import { autoinject, computedFrom } from 'aurelia-framework';
import { Actor, MyHttpApi, PublicStoreByIdResponse } from 'utils/api';
import { BrandUtil } from 'utils/brand';
import { Notify } from 'utils/notify';
import config from '../../config/environment.json';

@autoinject
export class AccountEdit {
  private store?: PublicStoreByIdResponse;
  private actor?: Actor;

  constructor(private client: MyHttpApi, private notify: Notify) {
  }

  async activate() {
    let storeId = parseInt(localStorage.getItem(config.sessionKey + "-store") || "0");
    if (storeId) {
      this.store = await this.client.publicStoreById({ id: storeId });
    }
    this.actor = await this.client.accountSelf();
  }

  @computedFrom("store")
  get brandStyle() {
    if (!this.store) {
      return "";
    } else if (this.store.storeBrand) {
      return `*/ ${this.store.storeBrand.style} /*`;
    } else {
      return BrandUtil.getCommentedCss(this.store.businessUnitBrand, this.client);
    }
  }

  async save() {
    if (!this.actor) {
      return;
    }
    await this.client.accountUpdateSelf({ ...this.actor, delete: false });
    this.notify.info("common.saved", {});
  }
}
